<template>
  <v-alert
    :class="{ wrapper: true, visible: visibility }"
    :color="type"
    :text="message"
    :icon="icon"
    density="comfortable"
  >
  </v-alert>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { Severity } from '@/components/feedback/message/message.d'
import EventBus from '@/utils/eventBus'

const visibility = ref(false)
const message = ref('')
const type = ref<Severity>('info')

const icon = computed(() => {
  switch (type.value) {
    case 'success':
      return '$success'
    case 'info':
      return '$info'
    case 'warning':
      return '$warning'
    case 'error':
      return '$error'
    default:
      return '$info'
  }
})

const handleMessage = ({ text, severity }) => {
  visibility.value = true
  message.value = text
  type.value = severity
  setTimeout(() => {
    visibility.value = false
  }, 6000)
}

onMounted(() => {
  EventBus.on('message', handleMessage)
})

onBeforeUnmount(() => {
  EventBus.off('message', handleMessage)
})
</script>

<style scoped lang="scss">
.wrapper {
  position: fixed !important;
  top: 1em;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 0.6rem;
  width: max-content;
  max-width: 30rem;
  margin: auto;
  opacity: 0;
  transform: translateY(-20px);
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  z-index: -1;
}

.visible {
  opacity: 1;
  transform: translateY(0);
  z-index: 9999;
}
</style>
