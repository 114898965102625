<template>
  <v-overlay
    persistent
    v-model="loading"
    class="d-flex align-center justify-center"
  >
    <v-progress-circular indeterminate color="primary" size="54" />
  </v-overlay>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import EventBus from '@/utils/eventBus'

const loading = ref(false)

const handleLoading = ({ active }: { active: boolean }) => {
  loading.value = active
}

onMounted(() => {
  EventBus.on('loading', handleLoading)
})

onBeforeUnmount(() => {
  EventBus.off('loading', handleLoading)
})
</script>

<style scoped lang="scss"></style>
