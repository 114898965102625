<template>
  <v-dialog
    :model-value="dialogAvis"
    @update:model-value="updateDialogAvis"
    persistent
    max-width="600"
    transition="dialog-top-transition"
    class="blur-background"
  >
    <v-card elevation="24" class="border-radius-none" rounded="xl">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="dialog-header font-weight-bold">{{
          $t('dashboard.avisConsultationDialog.title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="$emit('close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text v-if="consultations.length">
        <div class="text-center" v-if="consultations.length > 1">
          <v-pagination
            active-color="secondary"
            density="compact"
            color="primary"
            v-model="page"
            :length="consultations.length"
            :total-visible="5"
          ></v-pagination>
        </div>
        <div
          style="color: #757575"
          class="d-flex justify-center text-center avis-title"
          v-html="
            $t('dashboard.avisConsultationDialog.recap', {
              date: consultations[page - 1].date,
            })
          "
        ></div>

        <v-form
          v-model="isValidForm"
          @submit.prevent="sendReview(consultations[page - 1].id)"
        >
          <div
            style="background-color: #f3fcfa"
            class="pa-5 d-flex flex-column ga-5 rounded-lg"
          >
            <div class="d-flex flex-column ga-2">
              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.fullName') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].practitionerName
                }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.reason') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].appointmentReason
                }}</span>
              </div>
            </div>

            <v-divider />

            <div class="d-flex flex-column ga-2">
              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.duration') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].duration
                }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <span>{{ $t('dashboard.avisConsultationDialog.price') }}:</span>
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].price
                }}</span>
              </div>
            </div>

            <v-divider />

            <div class="d-flex flex-column ga-2">
              <div class="d-flex justify-space-between">
                <span>{{ $t('dashboard.avisConsultationDialog.time') }}:</span>
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].time
                }}</span>
              </div>
            </div>
          </div>

          <div class="mt-4 text-center">
            <div class="confirm-title">
              {{ $t('dashboard.avisConsultationDialog.confirm') }}
            </div>
            <div class="d-flex justify-center mt-2">
              <v-btn
                @click="() => toggleThumbUp(true)"
                icon
                color="#12C29B33"
                class="text-white"
              >
                <v-icon width="35px" height="31.25px" color="#343330">{{
                  thumbUp ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'
                }}</v-icon>
              </v-btn>
              <v-btn
                @click="() => toggleThumbUp(false)"
                icon
                color="#C2121533"
                class="mx-2 text-white"
              >
                <v-icon width="35px" height="31.25px" color="#343330">{{
                  thumbUp === false
                    ? 'mdi-thumb-down'
                    : 'mdi-thumb-down-outline'
                }}</v-icon>
              </v-btn>
            </div>
            <div v-if="selectOptionMsg" class="mb-2 text-red">
              {{ selectOptionMsg }}
            </div>
          </div>

          <div>
            <label>{{ $t('dashboard.avisConsultationDialog.note') }}</label>
            <v-textarea
              v-model="comment"
              :placeholder="$t('dashboard.avisConsultationDialog.placeholder')"
              persistent-hint
              outlined
              class="w-full mt-2"
              rows="5"
              :rules="commentRules"
              :hint="$t('dashboard.avisConsultationDialog.avis_feedback')"
            ></v-textarea>
          </div>

          <div class="d-sm-flex ga-sm-2 mt-5">
            <v-btn
              @click="$emit('close')"
              color="#E0E0E0"
              class="w-sm-50 w-100"
              >{{ $t('cancel_btn') }}</v-btn
            >
            <v-btn
              class="w-sm-50 w-100 mt-2 mt-sm-0"
              color="secondary"
              type="submit"
              >{{ $t('dashboard.avisConsultationDialog.soumettre') }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'
import createHttpClient from '@/api/httpClient'
import MessageService from '../feedback/message/messageService'
import { useDialogAvisConsultationStore } from '@/store/dialogAvisConsultationStore/dialogAvisConsultationStore'
import { useUserStore } from '@/store/user/userStore'

export default {
  props: {
    dialogAvis: Boolean,
  },
  emits: ['update:dialogAvis', 'close'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()
    const dialogAvisConsultationStore = useDialogAvisConsultationStore()

    // const consultations = ref([
    //   {
    //     id: 1,
    //     photo: 'avatar.png',
    //     firstName: 'Thomas',
    //     lastName: 'Duronpoint',
    //     reason: 'Motif 1',
    //     duration: '35min',
    //     price: '55€',
    //     date: 'Jeu. 16 juin',
    //     time: '14h30',
    //     note: '',
    //   },
    //   {
    //     id: 1,
    //     photo: 'avatar.png',
    //     practitionerName: 'Thomas',
    //     lastName: 'Duronpoint',
    //     appointmentReason: 'Motif 1',
    //     duration: '35min',
    //     price: '55€',
    //     date: 'Jeu. 16 juin',
    //     time: '14h30',
    //     note: '',
    //   },
    // ])

    const page = ref(0)
    const isValidForm = ref(false)
    const selectOptionMsg = ref('')

    const consultations = computed(() => {
      page.value = dialogAvisConsultationStore.consultations.length as number
      return dialogAvisConsultationStore.consultations
    })

    const consultationLists = computed(() => {
      return dialogAvisConsultationStore.consultations
    })

    const updateDialogAvis = value => {
      emit('update:dialogAvis', value)
    }

    const thumbUp = ref(null)

    const toggleThumbUp = v => {
      thumbUp.value = v
    }

    const toggleDialog = () => {
      updateDialogAvis(false)
    }

    const validate = () => {
      updateDialogAvis(false)
    }

    const sendReview = async id => {
      selectOptionMsg.value = ''

      if (!isValidForm.value) return false

      if (thumbUp.value === null) {
        selectOptionMsg.value =
          'Veuillez indiquer votre avis en sélectionnant une option.'
        return false
      }

      LoadingBackdropService.start()
      try {
        // Handle consultation confirmation
        await httpClient.post(
          `/opinion/opinion/${id}`,
          { opinion: comment.value, thumbUp: thumbUp.value },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      const userStore = useUserStore()
      // if not connected, do not fetch
      if (!userStore.user.token) {
        LoadingBackdropService.stop()
        return
      }

      comment.value = ''

      await dialogAvisConsultationStore.fetchConsultations()

      LoadingBackdropService.stop()
    }

    const like = ref(false)
    const dislike = ref(false)

    const toggleLike = () => {
      if (dislike.value) {
        dislike.value = false
      }
      like.value = !like.value
    }

    const toggleDislike = () => {
      if (like.value) {
        like.value = false
      }
      dislike.value = !dislike.value
    }

    const comment = ref('')

    const commentRules = [(v: any) => v.length <= 300 || t('300_caracteres')]

    return {
      t,
      consultations,
      sendReview,
      dialogAvisConsultationStore,
      updateDialogAvis,
      toggleDialog,
      validate,
      page,
      like,
      dislike,
      toggleLike,
      toggleDislike,
      consultationLists,
      commentRules,
      comment,
      toggleThumbUp,
      thumbUp,
      isValidForm,
      selectOptionMsg,
    }
  },
}
</script>

<style scoped>
/* .v-card-text .v-btn {
  min-width: 70px;
  border-radius: 60px !important;
} */

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

/* .border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
} */

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.dialog-header {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

/* .h-overflow {
  max-height: 550px;
  overflow-y: auto;
} */

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  font-size: 13px;
  color: gray;
  margin-left: 16px;
  margin-right: 16px;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.avis-title {
  color: #757575;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.confirm-title {
  color: #616161;
  font-size: 20px;
  font-weight: 500;
}

/* .border-radius-none {
  border-radius: 0 !important;
} */
</style>
