/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Root Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
// Services in store to initialize globally
import { useUserStore } from '@/store/user/userStore'
import { useGoogleMapsStore } from './store/google-maps/googleMapsStore'

const app = createApp(App)
const head = createHead()

registerPlugins(app)

// Initialize Google Maps Service
const googleMapsStore = useGoogleMapsStore()
googleMapsStore.initialize(import.meta.env.VITE_GOOGLE_MAPS_API_KEY)

const userStore = useUserStore()
userStore.init()

app.use(head)
app.mount('#app')
