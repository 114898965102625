<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="updateDialogAppointment"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="font-weight-bold">{{
          $t('dashboard.appointmentMissingDialog.title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="validate"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-form v-model="isValidForm" @submit.prevent="onSubmit">
        <v-card-text v-if="consultations.length">
          <div class="text-center" v-if="consultations.length > 1">
            <v-pagination
              active-color="secondary"
              density="compact"
              color="primary"
              v-model="page"
              :length="consultations.length"
              :total-visible="5"
            ></v-pagination>
          </div>

          <div
            style="color: #757575"
            class="d-flex justify-center text-center abscence-title"
            v-html="
              $t('dashboard.avisConsultationDialog.recap', {
                date: consultations[page - 1].date,
              })
            "
          ></div>

          <div
            style="background-color: #f3fcfa"
            class="pa-5 d-flex flex-column ga-2 rounded-lg"
          >
            <div class="d-flex flex-column ga-2">
              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.fullName') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].customerName
                }}</span>
              </div>

              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.reason') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].appointmentReason
                }}</span>
              </div>
            </div>

            <v-divider />

            <div class="d-flex flex-column ga-2">
              <div class="d-flex justify-space-between">
                <span
                  >{{ $t('dashboard.avisConsultationDialog.duration') }}:</span
                >
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].duration
                }}</span>
              </div>

              <div class="d-flex justify-space-between">
                <span>{{ $t('dashboard.avisConsultationDialog.price') }}:</span>
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].price
                }}</span>
              </div>
            </div>

            <v-divider />

            <div>
              <div class="d-flex justify-space-between">
                <span>{{ $t('dashboard.avisConsultationDialog.time') }}:</span>
                <span class="text-teal font-weight-bold">{{
                  consultations[page - 1].time
                }}</span>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label>{{ $t('dashboard.avisConsultationDialog.note') }}</label>
            <v-textarea
              v-model="note"
              :placeholder="$t('dashboard.appointmentMissingDialog.note')"
              persistent-hint
              outlined
              class="w-full mt-2"
              rows="5"
              :rules="commentRules"
              :hint="$t('dashboard.avisConsultationDialog.avis_feedback')"
            ></v-textarea>
          </div>

          <div class="d-flex flex-column ga-4 mt-3">
            <div class="text-center text-gray-80 font-weight-medium">
              {{ $t('dashboard.avisConsultationDialog.confirmAbscenceTitle') }}
            </div>

            <div>
              <div class="d-sm-flex ga-3 px-6">
                <v-btn
                  class="w-sm-50 w-100"
                  :color="choice === 'dispute' ? 'red' : '#F3D0D0'"
                  @click="toggleChoice('dispute')"
                >
                  <span
                    :style="{
                      color: choice === 'dispute' ? 'white' : '#A82525',
                    }"
                  >
                    {{ $t('dashboard.appointmentMissingDialog.dispute') }}</span
                  >
                </v-btn>
                <v-btn
                  class="w-sm-50 w-100 mt-sm-0 mt-3"
                  :color="choice === 'confirm' ? 'primary' : '#C2ECE2'"
                  @click="toggleChoice('confirm')"
                >
                  <span
                    :style="{
                      color: choice === 'confirm' ? 'white' : '#004838',
                    }"
                  >
                    {{ $t('dashboard.appointmentMissingDialog.confirm') }}</span
                  >
                </v-btn>
              </div>
              <div v-if="choiceMsg" class="text-center text-red mt-1">
                {{ choiceMsg }}
              </div>
            </div>
          </div>
        </v-card-text>

        <!-- <v-card-text>
          <div class="h-overflow">
            <v-list>
              <v-list-item
                v-for="consultation in dialogYouAbsenceStore.consultations"
                :key="consultation.id"
                class="mb-4 border-list"
              >
                <v-card class="pa-4" outlined>
                  <v-row no-gutters>
                    <v-col
                      cols="2"
                      class="d-flex justify-center align-self-start"
                    >
                      <v-avatar size="80" class="ml-10">
                        <v-img :src="consultation.photo"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex flex-wrap align-items-center mb-2">
                        <div>
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.fullName')
                            }}
                            :</strong
                          >
                          {{ consultation.customerName }}
                        </div>
                      </div>
                      <div class="d-flex flex-wrap align-items-center mb-2">
                        <div>
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.reason')
                            }}
                            :</strong
                          >
                          {{ consultation.appointmentReason }}
                        </div>
                      </div>
                      <div class="d-flex flex-wrap mb-2">
                        <div class="flex-grow-1 mr-4">
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.duration')
                            }}
                            :</strong
                          >
                          {{ consultation.duration }}
                        </div>
                        <div class="flex-grow-1">
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.price')
                            }}
                            :</strong
                          >
                          {{ consultation.price }}
                        </div>
                      </div>
                      <div class="d-flex flex-wrap mb-2">
                        <div class="flex-grow-1 mr-4">
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.date')
                            }}
                            :</strong
                          >
                          {{ consultation.date }}
                        </div>
                        <div class="flex-grow-1">
                          <strong
                            >{{
                              $t('dashboard.appointmentMissingDialog.time')
                            }}
                            :</strong
                          >
                          {{ consultation.time }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="consultation.note"
                        :label="$t('dashboard.appointmentMissingDialog.note')"
                        outlined
                        class="w-full mt-2 custom-textarea"
                        rows="2"
                        variant="filled"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <div class="mt-4 text-center">
                        <div class="d-flex justify-center mt-2">
                          <v-btn
                            icon
                            color="green"
                            class="mx-2 text-white"
                            @click="confirmAbsence(consultation.id)"
                          >
                            {{ $t('dashboard.appointmentMissingDialog.confirm') }}
                          </v-btn>
                          <v-btn
                            icon
                            color="red"
                            class="mx-2 text-white"
                            @click="
                              disputeAbsence(consultation.id, consultation.note)
                            "
                          >
                            {{ $t('dashboard.appointmentMissingDialog.dispute') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text> -->

        <v-col cols="12">
          <span
            class="mr-auto dialog-text"
            v-html="$t('dashboard.appointmentMissingDialog.modifyChoiceInfo')"
          ></span>
        </v-col>

        <div class="d-flex justify-end pa-4">
          <v-btn class="" color="secondary" type="submit">
            {{ $t('dashboard.appointmentMissingDialog.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import createHttpClient from '@/api/httpClient'
import { useDialogYourAbsenceStore } from '@/store/dialogYourAbsenceStore/dialogYourAbsenceStore'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import MessageService from '@/components/feedback/message/messageService'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'

export default {
  props: {
    dialog: Boolean,
  },
  emits: ['update:dialog'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()
    const page = ref(0)

    const dialogYouAbsenceStore = useDialogYourAbsenceStore()

    const consultations_old = ref([
      // {
      //   id: 1,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: 'avatar.png',
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])

    const isValidForm = ref(false)

    const updateDialogAppointment = value => {
      emit('update:dialog', value)
    }

    const toggleDialog = () => {
      updateDialogAppointment(false)
    }

    const validate = () => {
      updateDialogAppointment(false)
    }

    const consultations = computed(() => {
      page.value = dialogYouAbsenceStore.consultations.length as number
      return dialogYouAbsenceStore.consultations
    })

    const choice = ref<'confirm' | 'dispute' | null>(null)
    const choiceMsg = ref('')

    const confirmAbsence = async id => {
      // Handle confirm confirmAbsence
      LoadingBackdropService.start()
      try {
        await httpClient.post(`/opinion/absence-confirm/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        choice.value = null
        note.value = ''
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      await dialogYouAbsenceStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const disputeAbsence = async (id, commentPatient) => {
      // Handle dispute absence
      LoadingBackdropService.start()
      try {
        await httpClient.post(`/opinion/absence-protest/${id}`, {
          commentPatient,
        })
        choice.value = null
        note.value = ''
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }

      await dialogYouAbsenceStore.fetchConsultations()

      LoadingBackdropService.stop()
    }

    const commentRules = [(v: any) => v.length <= 300 || t('300_caracteres')]

    const note = ref('')

    const onSubmit = async () => {
      choiceMsg.value = ''
      if (!isValidForm.value) return false

      if (!choice.value) {
        choiceMsg.value = 'Veuillez sélectionner une option.'
        return
      }

      if (choice.value === 'confirm') {
        await confirmAbsence(consultations.value[page.value - 1].id)
      } else {
        await disputeAbsence(consultations.value[page.value - 1].id, note.value)
      }
    }

    const toggleChoice = v => {
      choice.value = v
    }

    return {
      t,
      consultations,
      dialogYouAbsenceStore,
      updateDialogAppointment,
      toggleDialog,
      validate,
      confirmAbsence,
      disputeAbsence,
      page,
      commentRules,
      note,
      choice,
      onSubmit,
      toggleChoice,
      isValidForm,
      choiceMsg,
    }
  },
}
</script>

<style scoped>
.v-btn {
  min-width: 120px;
  border-radius: 60px !important;
}

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

.border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.dialog-header {
  background-color: #fef4eb;
  color: #f09954;
  text-align: center;
}

.h-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  color: gray;
  font-size: 14px;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}

.abscence-title {
  color: #757575;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
</style>
