<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="updateDialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
    class="blur-background"
  >
    <v-card elevation="24" class="border-radius-none">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="dialog-header">{{
          $t('dashboard.consultationDialog.title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="$emit('close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-form v-model="isValidForm" @submit.prevent="onSubmit">
        <v-card-text class="text-gray-80">
          <div class="text-center">
            <v-pagination
              v-if="consultations.length > 1"
              active-color="secondary"
              density="compact"
              color="primary"
              v-model="page"
              :length="consultations.length"
              :total-visible="5"
            ></v-pagination>
          </div>

          <div
            style="color: #757575"
            class="d-flex justify-center text-center avis-title"
            v-html="
              $t('dashboard.avisConsultationDialog.recap', {
                date: consultations[page - 1].date,
              })
            "
          ></div>

          <!-- <div class="d-flex flex-column ga-3 pr-10">
              <div>
                <span class="font-weight-bold"
                  >{{ $t('dashboard.consultationDialog.fullName') }}:
                </span>
                <span>{{ consultations[page - 1].customerName }}</span>
              </div>

              <div>
                <span class="font-weight-bold"
                  >{{ $t('dashboard.consultationDialog.reason') }}:
                </span>
                <span>{{ consultations[page - 1].appointmentReason }}</span>
              </div>

              <div class="d-flex justify-space-between">
                <div>
                  <span class="font-weight-bold"
                    >{{ $t('dashboard.consultationDialog.duration') }}:
                  </span>
                  <span>{{ consultations[page - 1].duration }}</span>
                </div>
                <div>
                  <span class="font-weight-bold"
                    >{{ $t('dashboard.consultationDialog.price') }}:
                  </span>
                  <span>{{ consultations[page - 1].price }}</span>
                </div>
              </div>

              <div class="d-flex justify-space-between">
                <div>
                  <span class="font-weight-bold"
                    >{{ $t('dashboard.consultationDialog.date') }}:
                  </span>
                  <span>{{ consultations[page - 1].date }}</span>
                </div>
                <div>
                  <span class="font-weight-bold"
                    >{{ $t('dashboard.consultationDialog.time') }}:
                  </span>
                  <span>{{ consultations[page - 1].time }}</span>
                </div>
              </div>
            </div> -->

          <div class="d-flex flex-column ga-3">
            <div
              style="background-color: #f3fcfa"
              class="pa-5 d-flex flex-column ga-2 rounded-lg"
            >
              <div class="d-flex flex-column ga-2">
                <div class="d-flex justify-space-between">
                  <span
                    >{{ $t('dashboard.consultationDialog.fullName') }}:</span
                  >
                  <span class="text-teal font-weight-bold">{{
                    consultations[page - 1].customerName
                  }}</span>
                </div>
                <div class="d-flex justify-space-between">
                  <span
                    >{{ $t('dashboard.avisConsultationDialog.reason') }}:</span
                  >
                  <span class="text-teal font-weight-bold">{{
                    consultations[page - 1].appointmentReason
                  }}</span>
                </div>
              </div>

              <v-divider />

              <div class="d-flex flex-column ga-2">
                <div class="d-flex justify-space-between">
                  <span
                    >{{
                      $t('dashboard.avisConsultationDialog.duration')
                    }}:</span
                  >
                  <span class="text-teal font-weight-bold">{{
                    consultations[page - 1].duration
                  }}</span>
                </div>
                <div class="d-flex justify-space-between">
                  <span
                    >{{ $t('dashboard.avisConsultationDialog.price') }}:</span
                  >
                  <span class="text-teal font-weight-bold">{{
                    consultations[page - 1].price
                  }}</span>
                </div>
              </div>

              <v-divider />

              <div class="d-flex flex-column ga-2">
                <div class="d-flex justify-space-between">
                  <span
                    >{{ $t('dashboard.avisConsultationDialog.time') }}:</span
                  >
                  <span class="text-teal font-weight-bold">{{
                    consultations[page - 1].time
                  }}</span>
                </div>
              </div>
            </div>

            <div class="mb-2">
              <label v-html="$t('dashboard.consultationDialog.note')"></label>
              <v-textarea
                v-model="comment"
                outlined
                persistent-hint
                class="w-full mt-2 custom-textarea"
                rows="5"
                :rules="commentRules"
                :hint="$t('dashboard.avisConsultationDialog.avis_feedback')"
              ></v-textarea>
            </div>
          </div>

          <div class="d-flex flex-column ga-2">
            <div class="text-center d-flex flex-column ga-2">
              <span class="font-weight-bold">{{
                $t('dashboard.consultationDialog.confirm')
              }}</span>
              <div>
                <div class="d-sm-flex ga-2 justify-center w-sm-75 mx-auto">
                  <v-btn
                    class="w-sm-50 w-100"
                    @click="toggleChoice('absent')"
                    :color="choice === 'absent' ? 'red' : '#F3D0D0'"
                  >
                    <span
                      :style="{
                        color: choice === 'absent' ? 'white' : '#A82525',
                      }"
                      >{{ $t('dashboard.consultationDialog.absent') }}</span
                    >
                  </v-btn>

                  <v-btn
                    block
                    class="w-sm-50 w-100 mt-2 mt-sm-0"
                    @click="toggleChoice('present')"
                    :color="choice === 'present' ? 'primary' : '#C2ECE2'"
                  >
                    <span
                      :style="{
                        color: choice === 'present' ? 'white' : '#004838',
                      }"
                    >
                      {{ $t('dashboard.consultationDialog.present') }}
                    </span>
                  </v-btn>
                </div>
                <span v-if="selectOptionMsg && !choice" class="text-red">{{
                  selectOptionMsg
                }}</span>
              </div>
            </div>

            <!-- <div class="h-overflow">
              <v-list>
                <v-list-item
                  v-for="consultation in dialogAskPractitionerConsultationStore.consultations"
                  :key="consultation.id"
                  class="mb-4 border-list"
                >
                  <v-card class="pa-4" outlined>
                    <v-row no-gutters>
                      <v-col
                        cols="2"
                        class="d-flex justify-center align-self-start"
                      >
                        <v-avatar size="80" class="ml-10">
                          <v-img :src="consultation.photo"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="10">
                        <div class="d-flex flex-wrap align-items-center mb-2">
                          <div>
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.fullName')
                              }}
                              :</strong
                            >
                            {{ consultation.customerName }}
                          </div>
                        </div>
                        <div class="d-flex flex-wrap align-items-center mb-2">
                          <div>
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.reason')
                              }}
                              :</strong
                            >
                            {{ consultation.appointmentReason }}
                          </div>
                        </div>
                        <div class="d-flex flex-wrap mb-2">
                          <div class="flex-grow-1 mr-4">
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.duration')
                              }}
                              :</strong
                            >
                            {{ consultation.duration }}
                          </div>
                          <div class="flex-grow-1">
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.price')
                              }}
                              :</strong
                            >
                            {{ consultation.price }}
                          </div>
                        </div>
                        <div class="d-flex flex-wrap mb-2">
                          <div class="flex-grow-1 mr-4">
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.date')
                              }}
                              :</strong
                            >
                            {{ consultation.date }}
                          </div>
                          <div class="flex-grow-1">
                            <strong
                              >{{
                                $t('dashboard.consultationDialog.time')
                              }}
                              :</strong
                            >
                            {{ consultation.time }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="consultation.comment"
                          :label="$t('dashboard.consultationDialog.note')"
                          outlined
                          class="w-full mt-2 custom-textarea"
                          rows="2"
                          variant="filled"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <div class="mt-4 text-center">
                          <strong>{{
                            $t('dashboard.consultationDialog.confirm')
                          }}</strong>
                          <div class="d-flex justify-center mt-2">
                            <v-btn
                              color="secondary"
                              class="mr-2 text-white"
                              @click="
                                () =>
                                  confirmPatientPresence(
                                    consultation.id,
                                    consultation.comment,
                                  )
                              "
                            >
                              {{ $t('dashboard.consultationDialog.present') }}
                            </v-btn>
                            <v-btn
                              color="error"
                              @click="
                                () =>
                                  confirmPatientAbsence(
                                    consultation.id,
                                    consultation.comment,
                                  )
                              "
                            >
                              {{ $t('dashboard.consultationDialog.absent') }}
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-list-item>
              </v-list>
            </div> -->

            <span class="dialog-text">
              {{ $t('dashboard.consultationDialog.modifyChoiceInfo') }}
            </span>

            <div class="d-flex justify-end">
              <v-btn class="" color="secondary" type="submit">
                {{ $t('dashboard.appointmentMissingDialog.save') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import MessageService from '../feedback/message/messageService'
import { useAskPatientPresenceToPractitionerStore } from '@/store/askPatientPresenceToPractitioner/askPatientPresenceToPractitionerStore'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'

export default {
  props: {
    dialog: Boolean,
  },
  emits: ['update:dialog', 'close'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const httpClient = createHttpClient()

    // TODO avoir le Call API /
    const consultations_old = ref([
      // {
      //   id: 1,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 2,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 3,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 4,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 5,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 6,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 7,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 8,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 9,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
      // {
      //   id: 10,
      //   photo: avatar,
      //   firstName: 'Thomas',
      //   lastName: 'Duronpoint',
      //   reason: 'Motif 1',
      //   duration: '35min',
      //   price: '55€',
      //   date: 'Jeu. 16 juin',
      //   time: '14h30',
      //   note: '',
      // },
    ])

    const page = ref(0)
    const comment = ref('')
    const choice = ref<'present' | 'absent' | null>(null)
    const selectOptionMsg = ref('')
    const toggleChoice = v => {
      choice.value = v
    }

    const consultations = computed(() => {
      page.value = dialogAskPractitionerConsultationStore.consultations
        .length as number
      return dialogAskPractitionerConsultationStore.consultations
    })

    const dialogAskPractitionerConsultationStore =
      useAskPatientPresenceToPractitionerStore()

    const updateDialog = value => {
      emit('update:dialog', value)
    }

    const toggleDialog = () => {
      updateDialog(false)
    }

    const validate = () => {
      updateDialog(false)
    }

    const isValidForm = ref(false)

    const onSubmit = async () => {
      selectOptionMsg.value = ''

      if (!isValidForm.value) return false

      if (choice.value === null) {
        selectOptionMsg.value = 'Veuillez sélectionner une option.'
        return false
      }

      if (choice.value === 'absent') {
        await confirmPatientAbsence()
      } else {
        await confirmPatientPresence()
      }
    }

    const confirmPatientPresence = async () => {
      LoadingBackdropService.start()
      // Handle consultation confirmation
      try {
        await httpClient.post(
          `/appointment-notification/${consultations.value[page.value - 1].id}/confirmed`,
          { comment: comment.value },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        choice.value = null
        comment.value = ''
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }
      await dialogAskPractitionerConsultationStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const confirmPatientAbsence = async () => {
      LoadingBackdropService.start()
      try {
        // Handle consultation confirmation
        await httpClient.post(
          `/appointment-notification/${consultations.value[page.value - 1].id}/unconfirmed`,
          { comment: comment.value },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        choice.value = null
        comment.value = ''
      } catch (error) {
        MessageService.error(t('common.error.errorHasOccurred'))
        console.error(error)
      }
      await dialogAskPractitionerConsultationStore.fetchConsultations()
      LoadingBackdropService.stop()
    }

    const markAsNoShow = id => {
      // Handle marking patient as no-show
    }

    const fetchPastConsultations = async () => {
      // Fetch past consultations
      // const response = await httpClient.get('/past-consultations')
      // if (response.status !== 200) {
      //   throw Error('An error occurred while fetching past consultations.')
      // }
      // consultations.value = response.data
    }

    const onOpen = async () => {
      // await fetchPastConsultations()
      // console.log('something ')
    }

    watch(
      () => props.dialog,
      async newState => {
        if (newState) {
          await onOpen()
        }
      },
      {
        immediate: true,
      },
    )

    const commentRules = [(v: any) => v.length <= 300 || t('300_caracteres')]

    return {
      t,
      updateDialog,
      toggleDialog,
      confirmPatientPresence,
      confirmPatientAbsence,
      dialogAskPractitionerConsultationStore,
      onOpen,
      consultations,
      page,
      comment,
      toggleChoice,
      choice,
      isValidForm,
      onSubmit,
      selectOptionMsg,
      commentRules,
    }
  },
}
</script>

<style scoped>
.v-card-text .v-btn {
  min-width: 100px;
}

.btn--save {
  min-width: 150px;
  background-color: #1a73e9;
}

.text-white {
  color: #fff !important;
}

.border-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
}

.v-card-text,
.v-list-item,
.v-card {
  background-color: #fff !important;
}

.v-list {
  background: none !important;
}

.h-overflow {
  max-height: 550px;
  overflow-y: auto;
}

.ml-10 {
  margin-left: -10px !important;
}

.align-self-start {
  align-self: flex-start;
}

.dialog-actions {
  background-color: white;
}

.dialog-text {
  font-size: 13px;
  color: gray;
}

.btn--save {
  background-color: #1a73e9;
  color: white;
}

.custom-textarea .v-input__control {
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.blur-background {
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 10px #00000072;
  &:deep(.v-overlay__scrim) {
    background-color: #fff;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}

.dialog-header {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.avis-title {
  color: #757575;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
}
</style>
